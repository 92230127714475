.container {
  width: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  border: 1px solid var(--color-progress);

  --color-progress: #3a0029;
}

.step {
  flex-grow: 1;
  border: 1px solid var(--color-progress);
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: rgba(var(--color-white-rgb) / 50%);
}

.current {
  composes: step;
  color: var(--color-white);

  --color-transparent: rgba(var(--color-white-rgb) / 0%);

  background-image: repeating-linear-gradient(
    120deg,
    var(--color-transparent),
    var(--color-transparent) 5px,
    var(--color-primary) 5px,
    var(--color-primary) 10px
  );
}

.completed {
  composes: step;
  color: var(--color-white);
  background: var(--color-progress);
}

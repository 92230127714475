.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;
  background: linear-gradient(180deg, var(--color-primary) 0%, #000 100%);
  padding-inline: calc((100% - min(90%, 1110rem / 16)) / 2);
  padding-block: 1.25rem calc(58rem / 16);
  box-sizing: border-box;
}

@media screen and (width <= 500px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding-block: 2rem 1.375rem;
  }
}

.heading {
  font-size: 1rem;
  margin: 0 0 0.25rem;
}

.navMenu {
  display: flex;
  flex-direction: column;
}

@media screen and (width <= 500px) {
  .navMenu {
    align-items: center;
  }
}

.navLink {
  display: inline-block;
  width: fit-content;
  color: var(--color-white);
  margin: 0.25rem 0;
}

.support {
  composes: navLink;
  margin: 1.5rem 0 0;
}

.copyright {
  font-size: calc(10rem / 16);
  align-self: flex-end;
  margin: 0;
}

@media screen and (width <= 500px) {
  .copyright {
    align-self: center;
    margin-top: 1.25rem;
  }
}

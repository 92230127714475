.container {
  box-sizing: border-box;
  width: 90%;
  max-width: calc(1200rem / 16);
  background: rgb(var(--color-primary-rgb) / 10%);
  border: 1px solid rgb(var(--color-primary-rgb) / 50%);
  padding: 2.25rem 3.125rem;
  margin-top: calc(-125rem / 16);
  display: flex;
  flex-direction: column;
}

@media screen and (width <= 500px) {
  .container {
    padding: 1rem 0.75rem;
  }
}

.heading {
  composes: regularText from '../Typography/Typography.module.css';
  margin: 0 0 0.25rem;
}

.txStatus {
  composes: subText from '../Typography/Typography.module.css';
  padding-left: 2.5rem;
  margin: 0 0 1.75rem;
  background: no-repeat left center/auto;
}

@media screen and (width <= 500px) {
  .txStatus {
    background-size: 1.5rem;
  }
}

.txPrepared {
  composes: txStatus;
  background-image: url('./pending.svg');
}

.txComplete {
  composes: txStatus;
  background-image: url('./ok.svg');
}

.txError {
  composes: txStatus;
  background-image: url('./error.svg');
  margin: 0;
}

.addressContainer {
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

@media screen and (width <= 500px) {
  .addressContainer {
    flex-direction: column;
    align-items: center;
  }
}

.address {
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  overflow-wrap: break-word;
  max-width: 100%;
}

.addressName {
  padding-left: 0.25rem;
}

.addressValue {
  composes: importantText from '../Typography/Typography.module.css';
  background: #3a0029;
  border: 1px solid var(--color-orange);
  padding: 0.25rem;
}

.web3Name {
  composes: regularText from '../Typography/Typography.module.css';
  padding-left: 0.25rem;
  font-size: 2.25rem;
  line-height: 2.25rem;
}

.termsLine {
  display: flex;
  align-items: center;
  align-self: center;
  box-sizing: border-box;
  width: calc(100% + 6.25rem);
  margin: 0.5rem 0 1.5rem;
  padding: 0.5rem 3.125rem;
  background: rgb(var(--color-primary-rgb) / 50%);
}

@media screen and (width <= 500px) {
  .termsLine {
    width: calc(100% + 1.5rem);
    padding: 0.5rem 1.75rem;
  }
}

.termsLineEnabled {
  composes: termsLine;
  background: var(--color-orange);
}

.termsLabel {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.termsLabelText {
  margin: 0;
}

.accept {
  display: none;
}

.checkbox {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  border: 1px solid var(--color-white);
  border-radius: 2px;
  background: var(--color-primary);
  position: relative;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.checkbox::before {
  content: ' ';
  background: url('./arrow.svg') no-repeat center/contain;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  left: -1.25rem;
  top: 0.25rem;
}

.accept:checked + .checkbox::after {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
  content: ' ';
  background: url('./checkmark.svg') no-repeat center/contain;
}

.termsLink {
  color: var(--color-white);
}

.incomplete {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.instruction {
  composes: importantText from '../Typography/Typography.module.css';
}

.cost {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.costValue {
  font-size: 2.25rem;
  line-height: 2.25rem;
  margin-block: 0.25rem;
}

.costDetails {
  font-size: 0.875rem;
  font-style: italic;
}

.orderLine {
  margin-block: 0;
}

.order {
  composes: buttonPrimary from '../Buttons/Buttons.module.css';
  width: auto;
  padding-inline: 1rem;
}

.paypal {
  width: calc(250rem / 16);
  height: 2.25rem;
}

@media screen and (width <= 500px) {
  .paypal {
    width: 100%;
    height: auto;
  }
}

.servicesHeading {
  composes: regularText from '../Typography/Typography.module.css';
  margin-top: 2.75rem;
}

.servicesSubline {
  margin-block: 0.375rem;
}

.services {
  padding-left: 1.5rem;
}

.serviceLink {
  color: var(--color-white);
}

.restart {
  composes: buttonPrimary from '../Buttons/Buttons.module.css';
  margin-top: 1rem;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.dialog[open]::backdrop {
  background: rgb(68 0 49 / 30%);
  backdrop-filter: blur(3px);
}

.dialog[open] {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: var(--color-white);
  border: none;
  margin: 0;
  gap: 1.5rem;
}

.dialog[open]::before {
  content: '';
  width: calc(50rem / 16);
  height: calc(50rem / 16);
  background: url('spinner.svg') no-repeat center/contain;
  animation: rotation 1.5s infinite linear;
}
